import React from 'react';
import './index.css';

const WelcomeCompoment = () => {
  return (
    <div className="page-container">
      <div className="welcome-message__box">
        <span className="title">
          Enhance Security With Smart Watches for Guards
        </span>
        <span className="sub-title">
          Our smart watches provide real-time tracking and monitoring
          for security guards,ensuring optimal safety and efficiency
        </span>

        <div className="btn-center-group">
          <button className="solid-btn-style">Get Started</button>
          <button className="white-btn-style">Learn More</button>
        </div>
      </div>

      <div className="grey-container"></div>
    </div>
  );
};
export default WelcomeCompoment;
