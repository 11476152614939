import React from "react";
import './index.css'


const SixthPage = () => {
    return (
        <>
        <div className="sixth-page-container">
          <div className="sixth-page-box">
           <div className="sixth-page-items">
           <span className="page-title ">Discover the Power of Smart</span>
            <span className="page-subtitle">Track and Enhance Security with Our Smart Watches</span>
            <div className="page-box-button">
            <button className="p-button">Request</button>
            <button className="s-button">Contact</button>
           </div>
            </div>
          </div>
        </div>
        </>
    )
}


export default SixthPage;