import React from 'react';
import './index.css';
import { BiTimer } from 'react-icons/bi';
import { HiOutlineShieldExclamation } from 'react-icons/hi2';
import phone from 'assets/images/phone.jpeg';

const FourthPage = () => {
  return (
    <>
      <div className="fourth-page">
        <div className="fourth-page-box">
          <div className="fourth-page-box__left">
            <span className="line-title">
              Discover the Power of Smart Watches for Security Guard
              Management
            </span>
            <span className="lower-line-title">
              Our smart watches provide real-time tracking and
              monitoring capabilities, ensuring the safety and
              efficiency of security guards for security companies.
            </span>
            <div className="fourth-page-box-groups-cards">
              <div className="fourth-page-box-card">
                <div className="line-icon">
                  <HiOutlineShieldExclamation
                    size={20}
                    color="#ffffff"
                  />
                </div>
                <span className="h2">Enhanced security</span>
                <span className="h3">
                  Track the movements of security guards to ensure
                  optimal coverage and response times.
                </span>
              </div>
              <div className="fourth-page-box-card">
                <div className="line-icon">
                  <BiTimer size={20} color="#ffffff" />{' '}
                </div>
                <span className="h2">Improved Efficiency</span>
                <span className="h3">
                  Streamline operations by task assignments, and
                  incident reporting.
                </span>
              </div>
            </div>
          </div>
          <div className="fourth-page-box__right">
            <img src={phone} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FourthPage;
