import React from 'react';
import './index.css';
import NavigationBar from 'components/NavigationBar/indext';
import WelcomeComponent from 'components/WelcomeComponent';
import SecondPage from 'pages/SecondPage';
import ThirdPage from 'pages/ThirdPage';

import SixthPage from 'pages/SixthPage';
import SeventhPage from 'pages/SeventhPage';
import FifthPage from 'pages/FifthPage';
import FourthPage from 'pages/FourthPage';

const StartPage = () => {
  return (
    <div className="start-page-container">
      <div className="start-page-container__container">
        <NavigationBar />
        <WelcomeComponent />
      </div>
      <SecondPage />
      <ThirdPage />
      <FourthPage />
      <FifthPage />
      <SixthPage />
      <SeventhPage />
    </div>
  );
};

export default StartPage;
