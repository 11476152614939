import React from 'react';
import './index.css';
import Arm from 'assets/images/Arm.png';

const FifthPage = () => {
  return (
    <>
      <div className="fifth-page">
        <div className="fifth-page-box">
          <div className="fifth-page-box__left">
            <button className="bottom-button">Efficiency</button>
            <span className="bottom-left">
              Real-World Scenarios: Tracking Security Guards with
              Smart Watches
            </span>
          </div>
          <div className="fifth-page-box__right">
            <span className="first-line-tile">
              Our smart watches have proven to be highly efficient and
              effective in real-world scenarios. With advanced
              tracking capabilities, they provide accurate data on
              security guards' movements and activities.
            </span>
            <div className="box-cards">
              <div className="right-box-cards">
                <span className="numbers">50%</span>
                <span className="h3">
                  Increase in productivity and response time.
                </span>
              </div>
              <div className="right-box-cards">
                <span className="numbers">50%</span>
                <span className="h3">
                  Reduction in security incidents and breaches.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="picture">
          <img src={Arm} alt="" />
        </div>
      </div>
    </>
  );
};

export default FifthPage;
