import React from "react"; 
import './index.css'
import Logo from "assets/images/logo.png"
import { MdMenu} from "react-icons/md";

const NavigationBar = () => {
    return (
    <div className="nav-bar-container">
       <div className="nav-bar-container__left">
       <div className="nav-bar-logo">
            <img src={Logo} alt="" />
        </div>
       </div>
       <div className="nav-bar-container__right">
            <div className="nav-bar-options__left">
                <a className="btn-link-style active-btn" href="#">Home</a>
                <a className="btn-link-style" href="#">Features</a>
                <a className="btn-link-style" href="#">Pricing</a>
                <a className="btn-link-style" href="#">Contact</a>  
            </div>
            <div className="nav-bar-options__right">
                <button className="solid-btn-style">Get Started</button>
                <button className="white-btn-style">Sign in</button>
                <MdMenu size={40} className="menu-btn" />
                
            </div>
       </div>
    </div>
)
}

export default NavigationBar;


