import React from "react";
import './index.css'
import Logo from "assets/images/logo.png"
import { GrLinkedin } from "react-icons/gr";
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";


const SeventhPage = () => {
        return(
            <>
            <div className="seventh-page">
            <div className="seventh-page-container">
       <div className="seventh-page-container__left">
       <div className="seventh-page-logo">
            <img src={Logo} alt="" />
        </div>
       </div>
       <div className="seventh-page-container__right">
            <div className="seventh-page-options__left">
                <a className="btn-link-style" href="#">Home</a>
                <a className="btn-link-style" href="#">Features</a>
                <a className="btn-link-style" href="#">Pricing</a>
                <a className="btn-link-style" href="#">Contact</a>  
            </div>
            <div className="seventh-page-options__right">
                <div className="nav-icons">
            <FaFacebook />
            <FaInstagram />
            <FaXTwitter />
            <GrLinkedin />
            <FaYoutube />
            </div>
            </div>
            </div>
            </div>
            <div className="page-footer">
                <div className="separator">
            

                </div>
                <div className="page-footer-bottom">
                <div className="page-footer__left">
                    <span>2024 Protekt. All right reserved.</span>
                </div>
                <div className="page-footer__right">
                    <a href="#">Privacy Policy</a>
                    <a href="#">Terms of Service</a>
                    <a href="#">Cookies Settings</a>
                </div>
                </div>
            </div>
            </div>
            </>
        )
}

export default SeventhPage;