import React from 'react';
import './index.css';
import Watch from 'assets/images/watch.jpeg';
import {
  IoIosArrowForward,
  IoMdCheckmarkCircleOutline,
} from 'react-icons/io';

const SecondPage = () => {
  return (
    <div className="second-container-page">
      <div className="second-page-container__box">
        <div className="second-page-container-left__box">
          <button className="inn-btn">Innovative</button>
          <div className="second-page-container-left__box-items">
            <span className="bottom-title">
              Enhance Security With Smart Watches
            </span>
            <span className="page-text">
              Equip your security company with advanced smart watches
              designed to enhance the safety and efficiency of your
              security guards.Our technology provides real-time
              location monitoring and intelligent alerts,ensuring your
              team is always connected and protected.
            </span>
            <div className="select">
              <div className=" grouped-option">
                <IoMdCheckmarkCircleOutline
                  size={20}
                  color="#344BFD"
                />
                <span className="step">
                  Real-time location monitoring for enhanced security
                </span>
              </div>
              <div className="grouped-option1">
                <IoMdCheckmarkCircleOutline
                  size={20}
                  color="#344BFD"
                />{' '}
                <span className="step">
                  Intelligent alerts for immediate response to
                  emergencies
                </span>
              </div>
              <div className="grouped-option2">
                <IoMdCheckmarkCircleOutline
                  size={20}
                  color="#344BFD"
                />
                <span className="step">
                  Efficient communication features for seamless
                  coordination
                </span>
              </div>
            </div>
          </div>
          <div className="group-button">
            <button className="learn-more-button">Learn More</button>
            <button className="sign-up-button">
              <span>Sign Up</span>
              <IoIosArrowForward size={20} />
            </button>
          </div>
        </div>
        <div className="second-page-container-right__box">
          <img src={Watch} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SecondPage;
