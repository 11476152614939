import React from 'react';
import './index.css';
import { IoIosArrowForward } from 'react-icons/io';
import { LuPencilRuler } from 'react-icons/lu';
import { FaLocationCrosshairs } from 'react-icons/fa6';
import { RiBatteryShareLine } from 'react-icons/ri';

const ThirdPage = () => {
  return (
    <div className="third-page">
      <div className="third-box">
        <button className="constant-button">Cutting-edge</button>
        <span className="top-title">
          Experience the Future of Security Management
        </span>
        <span className="lower-title">
          Our smart watch solutions offer real-time tracking, a
          durable design, and long battery life. Keep your security
          operations connected and secure with our innovative
          technology.
        </span>
      </div>
      <div className="card-box">
        <div className="cards">
          <div className="box-icon">
            <FaLocationCrosshairs size={40} />
          </div>
          <span className="h1">Real-Time Tracking</span>
          <span className="p">
            Track the movements of your security guards in real tiime.
          </span>
        </div>
        <div className="cards">
          <div className="box-icon">
            <LuPencilRuler size={40} />{' '}
          </div>
          <span className="h1">Durable Design</span>
          <span className="p">
            Our smart watch is built to withstand the toughest
            environments.
          </span>
        </div>
        <div className="cards">
          <div className="box-icon">
            {' '}
            <RiBatteryShareLine size={40} />{' '}
          </div>
          <span className="h1">Long Battery Life</span>
          <span className="p">
            With our smart watch,you can rely on extended battery life
          </span>
        </div>
      </div>
      <div className="lower-button">
        <button className="learn-more-btn">Learn More</button>
        <button className="sign-up-btn">
          sign up <IoIosArrowForward size={20} />
        </button>
      </div>
    </div>
  );
};

export default ThirdPage;
